import React, {useEffect, useState} from 'react';
import styled from "styled-components";

import Logo from '../../images/logo.png'

import { useHistory, useParams } from "react-router-dom";

import { FaMap, FaPhone, FaPassport, FaCarAlt, FaAlignJustify } from "react-icons/fa";

import db from '../../firebase/firebase.config';

export default function Search() {
  
  const [textField, setTextField] = useState('');

  let history = useHistory();
  let { text } = useParams();

  const [negocios, setNegocios] = useState([])
  
  const fetchNegocios = async() => {
  
    const response = db.collection('negocios');
    
    const data = await response.get();

    let idx = 0;
    let arr = [];
    for (idx  in data.docs) { 
      if (text != undefined) {
        let nome = data.docs[idx].data().nome.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()
        if (nome.includes(text.toString().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))) {
          arr.push(data.docs[idx].data())
        } else {
          if (nome.includes(text.toString().toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""))) {
            arr.push(data.docs[idx].data())
          } 
        }
      } else {
        arr.push(data.docs[idx].data())
      }
    }
    setNegocios(arr)

  }
  
  useEffect(() => {
    fetchNegocios();
  }, [])

    return (
    <Box>
        <TitleBox>
            <ImgLogo src={Logo} />
            <TextInput type='text' placeholder={text} onChange={ e => { setTextField(e.target.value) } } />
            <ButtonInput type='submit' value='Pesquisar' onClick={ () => { history.push('/redirect/' + textField ) } } />
            <ButtonInput type='submit' value='Voltar' onClick={ () => { history.push('/') } } />
        </TitleBox>
        <ContentBox>
            <SubContentBox>
              {negocios.map((negocio) => {
                return (
                  <NegocioBox>
                    <Title>{negocio.nome}</Title>
                    <Info> <FaAlignJustify style={{ marginRight: '2%' }} /> {negocio.descricao} </Info>
                    <Info> <FaMap style={{ marginRight: '2%' }} /> {negocio.endereco} </Info>
                    <Info> <FaPhone style={{ marginRight: '2%' }} /> {negocio.telefone} </Info>
                    <Info> <FaPassport style={{ marginRight: '2%' }} /> {negocio.social} </Info>
                    {negocio.entrega === true &&
                      <Info> <FaCarAlt style={{ marginRight: '2%' }} /> Entega | Domicílio </Info>
                    }
                  </NegocioBox>
                );
              })}
              { (negocios.length == 0) &&
                <h5> Sem resultados encontrados  </h5>
              }
            </SubContentBox>
        </ContentBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FF9C13;
`;

const TitleBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin: 0px;
  padding-top: 1%;
  padding-bottom: 1%; 
  background-color: white;
`;

const ImgLogo = styled.img`
  width: 10%;
  
  padding-left: 1.5%;
  padding-right: 1.5%;
`;

const TextInput = styled.input`
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;
  background-color: white;

  &:active{
    background-color: #f8e0bf;
  }

  &:hover{
    background-color: #f8e0bf;
  }
 
  ::placeholder {
    color: #FF9C13;
  }

  padding: 1%;

  width: 45vw;
  
`;

const ButtonInput = styled.input`
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;
  margin-left: 0.5%;

  background-color: white;

  text-align: center;

  padding: 1%;
  padding-left: 2%;
  padding-right: 2%;

  &:hover{
    background-color: #f8e0bf;
  }

  &:active{
    background-color: #FF9C13;
    color: white;
  }

`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 100%;
  width: 100%;
  margin-top: 2%;
`;

const SubContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

const NegocioBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 32%;
  padding: 1%;
  margin: 1%;
  background-color: white;
  border-radius: 12px;
`;

const Title = styled.h3`
  padding: 0%;
  margin: 0%;
  margin-bottom: 2%;
`;

const Info = styled.p`
  padding: 0%;
  margin: 0%;
  margin-bottom: 1%;
  margin-left: 2%;
  margin-top: 1%;
  font-size 0.75em;
  width: 90%;
`;