import { firebase } from '@firebase/app'
const firestore = require("firebase/firestore");


var firebaseConfig = {
    apiKey: "AIzaSyBnpzJVeRIJjd27kzSJFvUYuOtm0_mQWGE",
    authDomain: "negociosolidario-8c596.firebaseapp.com",
    projectId: "negociosolidario-8c596",
    storageBucket: "negociosolidario-8c596.appspot.com",
    messagingSenderId: "270548761275",
    appId: "1:270548761275:web:1d21c47811205e6a66479e",
    measurementId: "G-EG5PB3MTRD"
  };
  
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
