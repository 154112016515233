import React, { useEffect } from 'react';

import { useHistory, useParams } from "react-router-dom";

export default function Redirect() {
  
  let history = useHistory();
  let { text } = useParams();

  useEffect(() => {
    if (text != undefined) {
      history.push('/search/' + text )
    } else {
      history.push('/search/')
    }
  
  }, [])

    return (
    <div>

    </div>
  );
}   