import React, { useState } from 'react';
import styled from "styled-components";

import Logo from '../../images/logo.png'

import { useHistory, useParams } from "react-router-dom";

import db from '../../firebase/firebase.config';

export default function Search() {
  
  const [name, setName] = useState('');
  const [descricao, setDescricao] = useState('');
  const [local, setLocal] = useState('');
  const [Telefone, setTelefone] = useState('');
  const [Site, setSite] = useState('');
  const [Entrega, setEntrega] = useState(false);

  let history = useHistory();
  let { text } = useParams();

  const CadastrarAction = () => {

    db.collection('negocios').add({ endereco : local, nome : name, social : Site, telefone : Telefone, entrega : Entrega, descricao: descricao })
    .then(() => {
        history.push('/')
    })
    .catch((error) => {
        history.push('/error')
    });

  }
    return (
    <Box>
        <ContentBox>
            <SubContentBox>
                <NegocioBox>
                    <ImgLogo src={Logo} />
                    <Info> 
                        <h3>Nome do negócio</h3>
                        <TextInput type='text' placeholder={text} onChange={ e => { setName(e.target.value) } } required/>
                    </Info>
                    <Info> 
                        <h3>Descrição no Negócio</h3>
                        <TextInput type='text' placeholder={text} onChange={ e => { setDescricao(e.target.value) } } required/>
                    </Info>
                    <Info> 
                        <h3>Localização</h3> 
                        <TextInput type='text' placeholder={text} onChange={ e => { setLocal(e.target.value) } } required/>
                    </Info>
                    <Info> 
                        <h3>Telefone</h3> 
                        <TextInput type='text' placeholder={text} onChange={ e => { setTelefone(e.target.value) } } required/>
                    </Info>
                    <Info> 
                        <h3>Site/Rede social</h3> 
                        <TextInput type='text' placeholder={text} onChange={ e => { setSite(e.target.value) } } required/>
                    </Info>
                    <Info> 
                        <h3>Entrega</h3> 
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <AlternativeBox><input type='radio' name='True' value='True' id='True' onChange={ () => { setEntrega(true) }} /><AlternativeLabel htmlFor='True'>Aceito</AlternativeLabel></AlternativeBox>
                            <AlternativeBox><input type='radio' name='False' value='False' id='False' onChange={ () => { setEntrega(false) }} /><AlternativeLabel htmlFor='False'>Não aceito</AlternativeLabel></AlternativeBox>
                        </div>
                    </Info>
                    <div style={{display: 'flex', flexDirection: 'row', width: '95%'}}>
                        <ButtonInput type='submit' value='Cancelar' onClick={ () => { history.push('/') } } />
                        <ButtonInput type='submit' value='Cadastrar' onClick={ () => { CadastrarAction() } } />
                    </div>
                </NegocioBox>
            </SubContentBox>
        </ContentBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FF9C13;
  width: 100vw;
  height: 100vh;
`;

const TextInput = styled.input`
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;
  background-color: white;

  &:active{
    background-color: #f8e0bf;
  }

  &:hover{
    background-color: #f8e0bf;
  }
 
  ::placeholder {
    color: #FF9C13;
  }

  width: 100%;
  padding: 2%;
  height: 1em;
  
`;

const ImgLogo = styled.img`
  width: 40%;
  margin-bottom: 5%
`;

const ButtonInput = styled.input`
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;

  background-color: white;

  text-align: center;

  margin: 2%;
  padding: 2%;
  width: 95%;

  &:hover{
    background-color: #f8e0bf;
  }

  &:active{
    background-color: #FF9C13;
    color: white;
  }

`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SubContentBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const NegocioBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 1%;
  margin: 1%;
  background-color: white;
  border-radius: 12px;
`;

const Info = styled.p`
  padding: 0%;
  margin: 0%;
  margin-bottom: 1%;
  font-size 0.8em;
  width: 90%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AlternativeBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 2em;
  margin-bottom: 1em;

`;

const AlternativeLabel = styled.label`
  margin-left: 0.5em;
`;
