import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './pages/Home/index';
import Search from './pages/Search/index';
import Redirect from './pages/Redirect/index';
import Register from './pages/Register/index';

export default function App() {
  return (
    <div style={{width: '100vw', height: '100vh', backgroundColor: '#FF9C13'}}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/cadastrar" component={Register} />
        <Route exact path="/search/" component={Search} />
        <Route exact path="/search/:text" component={Search} />
        <Route exact path="/redirect/" component={Redirect} />
        <Route exact path="/redirect/:text" component={Redirect} />
      </Switch>
    </Router>
    </div>
  );
}