import React, { useState } from 'react';
import styled from "styled-components";

import Logo from '../../images/logo.png'

import { useHistory } from "react-router-dom";

export default function Home() {

  const [textField, setTextField] = useState('');

  let history = useHistory();

  return (
    <Box>
        <TitleBox>
            <ImgLogo src={Logo} />
        </TitleBox>
        <SearchBox>
            <TextInput type='text' onChange={ e => { setTextField(e.target.value) } } />
            <ButtonInput type='submit' value='Pesquisar' onClick={ () => { history.push('/search/' + textField )} } />
            <ButtonInput type='submit' value='Cadastrar' onClick={ () => { history.push('/cadastrar')} } />
        </SearchBox>
    </Box>
  );
}   

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
`;

const TitleBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0px;
`;

const ImgLogo = styled.img`
  width: 60%;
  margin-top: 15%;
  margin-bottom: 15%
`;

const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
`;

const TextInput = styled.input`
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;
  
  &:active{
    background-color: #f8e0bf;
  }

  &:hover{
    background-color: #f8e0bf;
  }

  padding: 1.2%;
  padding-left: 2%;
  padding-right: 2%;

  width: 40vw;
  
`;

const ButtonInput = styled.input`
  font-size: 1em;
  border-radius: 32px;
  color: #FF9C13;
  border: solid 1px #FF9C13;
  outline: none;
  margin-left: 1%;

  background-color: white;

  padding: 1.2%;

  width: 10vw;

  &:hover{
    background-color: #f8e0bf;
  }

  &:active{
    background-color: #FF9C13;
    color: white;
  }

`;